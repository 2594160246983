<template>
  <div class="card">
    <div class="card__inner">
      <div class="card__content">
        <div class="card__image">
          <img
            class="card__image-img"
            src="@/assets/img/images/te-about.png"
            alt=""
          />
        </div>
        <div class="grid">
          <div class="grid__item">
            <div class="grid__item-icon-wrapper">
              <img
                src="@/assets/img/icons/te-search.svg"
                alt="mobile services"
                class="grid__item-icon"
              />
            </div>
            <div class="grid__item-content">
              <p class="grid__item-title">
                {{ $t('home.services.serviceNames.mobile') }}
              </p>
              <p>{{ $t('home.services.serviceBody.mobile') }}</p>
            </div>
          </div>
          <div class="grid__item">
            <div class="grid__item-icon-wrapper">
              <img
                src="@/assets/img/icons/te-location.svg"
                alt="mobile services"
                class="grid__item-icon"
              />
            </div>
            <div class="grid__item-content">
              <p class="grid__item-title">
                {{ $t('home.services.serviceNames.fixed') }}
              </p>
              <p>{{ $t('home.services.serviceBody.fixed') }}</p>
            </div>
          </div>
          <div class="grid__item">
            <div class="grid__item-icon-wrapper">
              <img
                src="@/assets/img/icons/te-map.svg"
                alt="mobile services"
                class="grid__item-icon"
              />
            </div>
            <div class="grid__item-content">
              <p class="grid__item-title">
                {{ $t('home.services.serviceNames.internet') }}
              </p>
              <p>{{ $t('home.services.serviceBody.internet') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services Card',
};
</script>

<style scoped>
.card {
  padding-bottom: 30px;
}

.card__inner {
  padding: 40px 50px 0;
  display: flex;
  flex-direction: column;
}

.card__content {
  display: flex;
  align-items: center;
  gap: 80px;
}

.card__image {
  width: 100%;
  max-width: 561px;
}

.card__image .card__image-img {
  width: 100%;
  height: 100%;
}

.card__link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 60px;
  margin-top: 40px;
  font-weight: bold;
}

.card__link.mobile {
  display: none;
  margin-top: 0;
  margin-right: 0;
  font-size: 14px;
}

.grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.grid__item {
  box-shadow: 0px 2px 20px rgba(154, 154, 154, 0.16);
  border-radius: 15px;
  width: 100%;
  display: flex;
  padding: 28px;
  align-items: center;
  gap: 28px;
  background-color: #fff;
}

.grid__item-icon-wrapper {
  width: 60px;
  height: 60px;
  background-color: #f04e4c;
  border: 2px;
  border-radius: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.grid__item-title {
  font-size: 18px;
  font-weight: bold;
}

.grid__item-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .card {
    padding-bottom: 0;
  }

  .card__inner {
    padding: 0;
    display: block;
  }

  .card__content {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid__item {
    width: 100%;
    margin: 0 auto;
    padding: 12px;
  }

  .grid__item-title {
    font-size: 16px;
    padding: 0 5px;
  }
}

@media screen and (min-width: 551px) and (max-width: 900px) {
  .card__content {
    flex-direction: row;
  }

  .grid__item-title {
    font-size: 16px;
  }
}
</style>
