<template>
  <section class="hero">
    <div class="container">
      <div class="hero__inner">
        <div class="hero__top">
          <div class="heading heading--left">
            <h1 class="title fs32 pb30">{{ title }}</h1>
            <p class="desc fs16">{{ desc }}</p>
            <br />
          </div>
          <div class="hero__img">
            <img src="../../assets/img/images/hero-1.png" />
          </div>
        </div>
        <WhatIsTeamPay
          :title="$t('home.whatIs.title')"
          :desc="$t('home.whatIs.body')"
          :linkText="$t('home.whatIs.linkText')"
        />
      </div>
    </div>
  </section>
</template>

<script>
import WhatIsTeamPay from './WhatIsTeamPay.vue';
export default {
  name: 'Team Hero',
  props: {
    title: String,
    desc: String,
    desc2: String,
    desc3: String,
  },
  components: { WhatIsTeamPay },
};
</script>

<style scoped>
.hero {
  padding-top: 90px;
}

.hero__inner {
  display: flex;
  flex-direction: column;
}

.hero__top {
  display: flex;
  align-items: center;
  gap: 40px;
}

.hero__top .heading {
  width: 55%;
}

.hero__img {
  flex: 1;
}

.hero__img img {
  display: block;
  width: 100%;
  flex: 1;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .hero {
    padding-top: 100px;
  }

  .hero__top .heading {
    width: 100%;
  }

  .hero__img {
    display: none;
  }
}
</style>
