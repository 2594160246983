<template>
  <div class="burger">
    <button class="burger__btn">
      <span class="burger__line burger__line-top"></span>
      <span class="burger__line burger__line-middle"></span>
      <span class="burger__line burger__line-bottom"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Burger Menu'
};
</script>

<style scoped>
.burger {
  display: none;
}

@media screen and (max-width: 1024px) {
  .burger {
    display: block;
    z-index: 10;
  }

  .burger__btn {
    background: transparent;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 36px;
    height: 36px;
    padding: 9px 12px;
    cursor: pointer;
  }

  .burger__line {
    background-color: var(--teamBlue);
    width: 18px;
    height: 2px;
    transition: transform 0.2s ease;
  }

  .open .burger__line-top {
    transform: rotate(45deg) translateY(2px) translateX(2px);
  }

  .open .burger__line-middle {
    display: none;
  }

  .open .burger__line-bottom {
    transform: rotate(-45deg) translateY(-1px) translateX(2px);
  }
}
</style>
