<template>
  <main style="padding-top: 90px">
    <section class="information general">
      <div class="container--xl">
        <div class="information__inner">
          <div class="breadcrumb-container">
            <ul class="breadcrumb">
              <li>
                <RouterLink to="/">Գլխավոր</RouterLink>
              </li>
              <li><span>Terms and Conditions</span></li>
            </ul>
          </div>
          <div class="information__container">
            <div class="information__header">
              <div class="information__tab-list">
                <carousel :breakpoints="breakpoints" ref="carousel" :snapAlign="start">
                  <slide class="slider__item" v-for="tab in tabs" :key="tab.id" :class="{
                      'carousel__slide--visible': tab.id === activeTab
                    }">
                    <div class="information__tab-item">
                      <a :href="`#${activeTab}`" class="information__tab-link" :class="{
                          active: tab.id === currentHash
                        }" @click="selectTab(tab.id)">
                        {{ $t(tab.name) }}
                      </a>
                    </div>
                  </slide>
                  <template #addons="{ slidesCount }">
                    <navigation v-if="slidesCount > 5" />
                  </template>
                </carousel>
              </div>
            </div>
            <div class="information__content">
              <Terms v-if="currentHash === '1'" />
              <PrivacyPolicy v-if="currentHash === '2'" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import Terms from '../components/GeneralInformationTabs/Terms.vue'
import PrivacyPolicy from '../components/GeneralInformationTabs/PrivacyPolicy.vue'
export default {
  name: 'General Info',
  components: {
    Carousel,
    Slide,
    Navigation,
    Terms,
    PrivacyPolicy
  },
  metaInfo () {
    return {
      title: this.$t('meta.pages.generalInformation.title') + ' | Team Energy'
    }
  },
  data () {
    return {
      tabs: [
        {
          id: '1',
          name: 'general.tabs.tab1'
        },
        {
          id: '2',
          name: 'general.tabs.tab2'
        },
      ],
      breakpoints: {
        320: {
          itemsToShow: 2
        },
        550: {
          itemsToShow: 3
        },
        768: {
          itemsToShow: 5
        }
      },
      activeTab: '1',
      currentHash: window.location.hash.charAt(1)
    }
  },
  methods: {
    selectTab (id) {
      this.activeTab = id
      this.currentHash = id
      this.$refs.carousel.slideTo(this.currentHash - 1)
    },
    mountSlide () {
      this.$refs.carousel.slideTo(this.currentHash - 1)
    }
  },
  mounted () {
    this.$route.path = this.$route.path + window.location.hash
    this.activeTab = window.location.hash.charAt(1)
    this.mountSlide()
  },
  updated () {
    this.$route.path = this.$route.path + window.location.hash
    this.currentHash = window.location.hash.charAt(1)
    this.mountSlide()
  }
}
</script>

<style>
.general .carousel {
  width: 100%;
}
</style>
