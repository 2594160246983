<template>
  <div class="burger__switcher">
    <language-switcher
      tag="ul"
      class="burger__lang"
      v-slot="{links}"
      active-class="router-active-language"
    >
      <li
        class="lang-link__wrapper"
        v-for="link in links"
        :key="link.langIndex"
        :class="link.activeClass"
      >
        <router-link class="lang-link" :to="link.url">
          {{ link.langName }}
        </router-link>
      </li>
    </language-switcher>
  </div>
</template>

<script>
export default {
  name: 'Burger Languages Switcher',
  data() {
    return {
      isShown: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isShown = !this.isShown;
    },
    toggleClose() {
      this.isShown = false;
    },
    getCurrentLang(langname) {
      this.currentLangName = langname;
      this.toggleClose();
    },
  },
};
</script>

<style scoped>
.burger__lang {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 38px;
  position: relative;
  width: 100%;
}
.lang-link {
  font-size: 16px;
}
.router-active-language {
  font-weight: bold;
}
</style>
