eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if
<template>
  <div class="lang__wrapper">
    <language-switcher v-slot="{ links }" tag="ul">
      <li v-for="link in links" :key="link.langIndex">
        <button class="lang__btn" v-if="$route.path.includes(link.langIndex)" @click="toggleMenu">
          {{ currentLangName === '' ? link.langName : currentLangName }}
          <img src="../../assets/img/utils/arrow.svg" alt="" />
        </button>
      </li>
    </language-switcher>
    <language-switcher tag="ul" class="lang" v-slot="{ links }" active-class="router-active-language"
      :class="{ open: isShown }">
      <li class="lang-link__wrapper" v-for="link in links" :key="link.langIndex">
        <router-link class="lang-link" :to="link.url" @click="getCurrentLang(link.langName)">
          {{ link.langName }}
        </router-link>
      </li>
    </language-switcher>
  </div>
</template>

<script>
export default {
  name: 'Language Switcher',
  data () {
    return {
      isShown: false,
      currentLangName: ''
    };
  },
  methods: {
    toggleMenu () {
      this.isShown = !this.isShown;
    },
    toggleClose () {
      this.isShown = false;
    },
    getCurrentLang (langname) {
      this.currentLangName = langname;
      this.toggleClose();
    }
  }
};
</script>

<style scoped>
.lang__wrapper {
  position: relative;
}

.lang {
  display: flex;
  flex-direction: column;
  display: none;
  position: absolute;
  top: 35px;
  left: 35px;
  border-radius: 10px;
  transform: translateX(-85px);
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.open {
  display: block;
}

.lang-link {
  padding: 10px 50px;
  transition: all 0.2s ease;
  font-weight: bold;
}

.lang-link__wrapper:first-of-type .lang-link {
  border-radius: 10px 10px 0 0;
}

.lang-link__wrapper:nth-of-type(2) .lang-link {
  border-radius: 0;
}

.lang-link__wrapper:nth-of-type(3) .lang-link {
  border-radius: 0 0 10px 10px;
}

.lang-link:hover {
  background: var(--teamCyan);
}

.lang__btn {
  background: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  color: var(--teamBlue);
}

.router-link-exact-active {
  display: block;
}

@media screen and (max-width: 1024px) {
  .lang__wrapper {
    display: none;
  }
}
</style>
