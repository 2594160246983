<template>
  <main style="padding-top: 90px">
    <section class="app">
      <div class="container--xl">
        <div class="app__inner">
          <div class="heading m0a">
            <h1 class="title fs64 pb30 m0a">{{ $t('mobileApp.head.title') }}</h1>
            <p class="desc fs16 m0a">{{ $t('mobileApp.head.text') }}</p>
          </div>
          <div class="app__banner">
            <div class="app__image">
              <img
                src="../assets/img/images/app-banner.png"
                alt=""
              >
            </div>
            <div class="app__info">
              <h2 class="title fs32 mb20">{{ $t('mobileApp.block1.title') }}</h2>
              <ul class="app__info-list">
                <li class="app__info-item">{{ $t('mobileApp.block1.item1') }}</li>
                <li class="app__info-item">{{ $t('mobileApp.block1.item2') }}</li>
                <li class="app__info-item">{{ $t('mobileApp.block1.item3') }}</li>
                <li class="app__info-item">{{ $t('mobileApp.block1.item4') }}</li>
              </ul>
              <div class="app__info-links">
                <div class="app__info-stores">
                  <a href="https://apps.apple.com/am/app/team-energy/id6450178270">
                    <img
                      src="../assets/img/images/appstore.jpg"
                      alt=""
                    >
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=am.teamenergy">
                    <img
                      src="../assets/img/images/playmarket.jpg"
                      alt=""
                    >
                  </a>
                </div>
                <div class="app__info-qr">
                  <img
                    src="../assets/img/images/qrcode.png"
                    alt=""
                  >
                  <span class="fs14">{{ $t('mobileApp.block1.qr') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Energy App'
};
</script>

<style scoped>
.app__inner {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.app__inner .heading {
  margin-bottom: 80px;
}

.app__banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(40, 173, 191, 0.1);
  background-image: url('../assets/img/images/app-bg.svg');
  background-repeat: no-repeat;
  border-radius: 20px;
}

.app__image {
  flex: 1;
  margin-top: -80px;
}

.app__image img {
  width: 100%;
  display: block;
}

.app__info {
  flex: 1;
  padding: 54px 127px;
}

.app__info-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;
  margin-bottom: 32px;
}

.app__info-item::before {
  content: "";
  background-color: var(--teamRed);
  font-weight: bold;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
}

.app__info-links {
  display: flex;
  gap: 16px;
}

.app__info-stores {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.app__info-qr {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: baseline;
}

.app__info-qr img {
  display: block;
  width: 120px;
}

.app__info-qr span {
  color: #000;
}


@media screen and (min-width: 320px) and (max-width: 550px) {
  .app__banner {
    flex-direction: column-reverse;
    background-position: bottom;
    gap: 32px;
  }

  .app__inner .heading {
    margin-bottom: 0;
  }

  .app__image {
    margin-top: 0;
  }

  .app__info {
    padding: 20px 26px;
  }

  .app__info-qr {
    display: none;
  }
}
</style>