<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <Logo
          class="mobile"
          :src="logo"
        />
        <div class="footer__brand">
          <Logo
            class="desktop"
            :src="logo"
          />
          <div class="license">
            <p class="license__text">
              {{ $t('footer.financialMediator') }}
            </p>
          </div>
        </div>
        <div class="footer__menu">
          <FooterNav />
          <FooterContact />
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <p class="footer__copy">
        {{ $t('footer.copy') }}
      </p>
    </div>
  </footer>
</template>

<script>
import Logo from '../UI/Logo.vue';
import FooterNav from './FooterNav.vue';
import FooterContact from './FooterContact.vue';
export default {
  name: 'Footer',
  components: { Logo, FooterNav, FooterContact },
  data() {
    return {
      logo: require('../../assets/img/logo/logo-light.svg')
    };
  }
};
</script>

<style scoped>
.footer {
  background-color: var(--teamBlue);
  color: rgba(255, 255, 255, 0.7);
  margin-top: 25px;
}

.footer__inner {
  display: flex;
  align-items: flex-start;
  padding: 75px 0;
}

.footer__brand {
  max-width: 240px;
}

.license {
  padding-top: 15px;
}

.license__image {
  padding-bottom: 15px;
  max-width: 60px;
  max-height: 95px;
}

.license__text {
  font-size: 11px;
}

.footer__menu {
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
}

.footer__bottom {
  background-color: #022739;
  padding: 15px 0;
}

.footer__copy {
  text-align: center;
  font-size: 12px;
}

.mobile {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 900px) {
  .footer__inner {
    flex-direction: column;
    padding: 45px 0;
  }

  .footer__menu {
    flex-direction: column;
    padding-left: 0;
    width: 100%;
  }

  .footer__brand {
    order: 2;
    max-width: 100%;
  }

  .license {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 18px;
  }

  .license__image {
    max-width: 46px;
  }

  .license__text {
    font-size: 10px;
    line-height: 16px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
    padding-bottom: 35px;
  }

  .footer__copy {
    max-width: 290px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 901px) and (max-width: 1024px) {
  .footer__menu {
    padding-left: 35px;
  }
}
</style>
