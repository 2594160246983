<template>
  <div class="search-res">
    <router-link :to="href">
      <div class="header-result-item">
        <h4 class="title--xs">{{ title }}</h4>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    href: String
  }
};
</script>

<style scoped>
.search-res {
  display: flex;
}
.header-result-item {
  max-width: 244px;
  width: max-content;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  padding: 5px 10px;
  margin-bottom: 5px;
}
.search-res .title--xs {
  padding-bottom: 0;
  margin-right: 0;
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 20px;
}
</style>
