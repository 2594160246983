<template>
  <div class="functions">
    <div class="container">
      <div class="functions__inner">
        <div class="functions__info">
          <h2 class="title fs32 pb30">{{ title }}</h2>
        </div>
        <button
          class="btn btn-play"
          data-view="desktop"
          @click="openModal"
        >
          <img
            src="../../assets/img/images/play.svg"
            alt=""
          >
        </button>
      </div>
      <div class="function__play-mob">
        <button
          class="btn btn-play"
          data-view="mobile"
          @click="openModal"
        >
          <img
            src="../../assets/img/images/play.svg"
            alt=""
          >
        </button>
      </div>
    </div>
  </div>
  <Transition>
    <div
      v-if="showModal"
      id="myModal"
      class="modal"
      @click.self="closeModal"
    >
      <div class="modal-content">
        <span
          class="close"
          @click="closeModal"
        >&times;</span>
        <video
          class="video"
          muted
          playsinline
          controls
          ref="video"
        >
          <source
            :src="videoSrc"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  </Transition>
</template>

<script>
import { watch } from 'fs';
import Slider from './Slider.vue';

export default {
  name: 'How It Works',
  props: {
    title: String,
    desc: String
  },
  components: { Slider },
  data() {
    return {
      showModal: false,
      video: null,
      videoSrc: require('../../assets/video/video_2023-06-14_20-22-40.mp4')
    };
  },
  methods: {
    async openModal() {
      this.showModal = true;
      document.body.style.overflowY = 'hidden';
      await this.$nextTick();
      this.$refs.video.play();
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflowY = 'auto';
    }
  }
};
</script>

<style scoped>
.functions {
  padding-top: 32px;
}

.functions__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('../../assets/img/images/te-works.jpg');
  background-size: cover;
  height: 446px;
  border-radius: 10px;
  padding-inline: 72px;
  color: #fff;
}

.functions__info {
  max-width: 400px;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.title--m {
  padding-bottom: 20px;
}

.btn-play {
  background-color: transparent;
  border: none;
  width: auto;
  margin-right: auto;
  margin-left: 225px;
}

.btn-play[data-view="mobile"] {
  display: none;
}


.btn-play[data-view="desktop"] {
  display: block;
}

.function__play-mob {
  display: none;
}

.btn-play:hover {
  box-shadow: shadow;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.modal-content {
  margin-inline: auto;
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  position: relative;
}

.close {
  color: var(--teamBlue);
  background-color: rgba(255, 255, 255, 0.5);
  float: right;
  font-size: 50px;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 999;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 1;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.video {
  width: 100%;
  border-radius: 10px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {
  .functions__inner {
    flex-direction: column;
    background-image: none;
    height: auto;
    background-color: #fff;
    padding: 10px;
  }

  .functions__info {
    text-align: center;
    padding-bottom: 34px;
    color: var(--teamBlue);

  }

  .function__play-mob {
    display: flex;
    background-image: url('../../assets/img/images/te-works.jpg');
    background-size: cover;
    height: 220px;
    justify-content: center;
    align-items: center;
  }

  .btn-play[data-view="mobile"] {
    display: block;
    margin: 0;
  }

  .btn-play[data-view="mobile"] img {
    width: 50px;
  }

  .btn-play[data-view="desktop"] {
    display: none;
  }

  .modal-content {
    width: 100%;
    padding: 5px;
  }

  .close {
    font-size: 45px;
    top: -60px;
    right: 50%;
    transform: translateX(50%);
  }
}

@media screen and (min-width: 551px) and (max-width: 900px) {
  .functions__inner {
    flex-direction: column;
    background-color: #fff;
    background-image: none;
  }

  .functions__info {
    text-align: center;
    padding-bottom: 34px;
  }


  .modal-content {
    width: 100%;
    padding: 5px;
  }

  .close {
    font-size: 50px;
    top: -60px;
    right: 50%;
    transform: translateX(50%);
  }
}

@media screen and (min-width: 901px) and (max-width: 1024px) {
  .functions__inner {
    flex-direction: row;
  }

  .functions__info {
    max-width: 50%;
  }

  .slider {
    max-width: 50%;
  }
}
</style>
