<template>
  <nav class="nav" role="navigation" aria-label="Primary">
    <ul class="nav__menu">
      <li class="nav__menu-item" v-for="item in keys" :key="item.key">
        <router-link class="nav__menu-link" :to="$t(item.href)">
          {{ $t(item.key) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      keys: [
        {
          key: 'nav.about_us.title',
          href: 'nav.about_us.href'
        },
        {
          key: 'nav.branches.title',
          href: 'nav.branches.href'
        },
        {
          key: 'nav.charge.title',
          href: 'nav.charge.href'
        },
        {
          key: 'nav.app.title',
          href: 'nav.app.href'
        },
        {
          key: 'nav.contact.title',
          href: 'nav.contact.href'
        },
      ]
    };
  }
};
</script>

<style scoped>
.nav__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: var(--teamBlue);
}

.nav__menu-item {
  margin-right: 26px;
  padding: 5px 0;
}

.nav__menu-link {
  font-size: 16px;
  position: relative;
}

.nav__menu-link.router-link-exact-active {
  position: relative;
  color: var(--teamRed);
}


@media screen and (max-width: 1024px) {
  .nav {
    display: none;
  }
}
</style>
