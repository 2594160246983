<template>
  <div class="menu">
    <div class="menu__inner">
      <div class="mobile__nav">
        <ul class="menu__list">
          <li class="menu__list-item" v-for="item in keys" :key="item.key">
            <router-link class="menu__list-link" :to="$t(item.href)">
              {{ $t(item.key) }}
            </router-link>
          </li>
        </ul>
        <div class="menu__social">
          <a class="menu__social-link" href="https://www.facebook.com/Teampay.am" target="_blank">
            <img src="../../assets/img/icons/facebook.svg" alt="Facebook" />
          </a>
          <a class="menu__social-link" href="https://www.facebook.com/Teampay.am" target="_blank">
            <img src="../../assets/img/icons/instagram.svg" alt="Instagram" />
          </a>
          <a class="menu__social-link" href="https://www.facebook.com/Teampay.am" target="_blank">
            <img src="../../assets/img/icons/youtube.svg" alt="Youtube" />
          </a>
        </div>
      </div>
      <BurgerLanguageSwitcher />
    </div>
  </div>
</template>

<script>
import BurgerLanguageSwitcher from './BurgerLanguageSwitcher.vue';
export default {
  name: 'Mobile Menu',
  components: { BurgerLanguageSwitcher },
  data () {
    return {
      keys: [
        {
          key: 'nav.about_us.title',
          href: 'nav.about_us.href'
        },
        {
          key: 'nav.branches.title',
          href: 'nav.branches.href'
        },
        {
          key: 'nav.charge.title',
          href: 'nav.charge.href'
        },
        {
          key: 'nav.app.title',
          href: 'nav.app.href'
        },
        {
          key: 'nav.contact.title',
          href: 'nav.contact.href'
        },
      ]
    };
  },
};
</script>

<style scoped>
.menu {
  display: none;
}

@media screen and (max-width: 1024px) {
  .menu {
    display: block;
    background-color: #fff;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 56px;
    transform: translateX(200%);
    transition: transform 0.3s linear;
    border-top: 2px solid var(--teamLightGray);
  }

  .menu.open {
    transform: translateX(0);
    overflow: hidden;
  }

  .menu__inner {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-items: flex-start;
  }

  .menu__list {
    text-align: left;
    padding-inline: 20px;
  }

  .menu__list-item {
    margin-bottom: 27px;
  }

  .menu__list-link.router-link-exact-active {
    color: var(--teamRed)
  }


  .mobile__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 375px;
    margin-top: 35px;
  }

  .menu__social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    position: relative;
    width: 100%;
  }

  .menu__social::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #bababa;
    bottom: -21px;
  }
}
</style>
