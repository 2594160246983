<template>
  <main style="padding-top: 90px" v-if="availableSearches.length">
    <div class="search-page">
      <div class="container">
        <div class="search__inner">
          <div class="search__head">
            <h5 class="title fs20">Որոնում</h5>
            <div class="search__form-wrapper">
              <form class="search__form" @submit.prevent.enter="handleSearch">
                <label class="search__label" for="">
                  <input
                    class="search__input"
                    type="text"
                    placeholder="Որոնել․․․"
                    v-model="searchEntry"
                  />
                  <img
                    class="search__icon"
                    src="../assets/img/utils/search-dark.svg"
                    alt=""
                  />
                </label>
              </form>
            </div>
          </div>

          <div class="search__results">
            <div class="search__results-content">
              <div class="result-empty" v-if="!results.length">
                <p class="text">Ձեր որոնմանը համապատասխան ոչինչ չի գտնվել</p>
                <img
                  class="search__results-icon"
                  src="../assets/img/icons/empty_search.png"
                  alt=""
                />
              </div>
              <template v-else v-for="result in results" :key="result.id">
                <SearchResult
                  :title="result.title"
                  :desc="result.desc"
                  :href="result.href"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div v-else style="padding-top: 180px">
    <LoadingSpinner />
  </div>
</template>

<script>
import searchData from '../data/searches.json';
import SearchResult from '@/components/Search/SearchResult.vue';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
export default {
  name: 'Search Page',
  components: {SearchResult, LoadingSpinner},
  props: {
    query: String
  },
  data() {
    return {
      results: [],
      availableSearches: searchData,
      searchEntry: ''
    };
  },
  methods: {
    handleSearch() {
      this.results = this.availableSearches.filter((res) => {
        if (
          res.title
            .toLowerCase()
            .includes(this.searchEntry.toLowerCase().trim())
        ) {
          return res;
        }
      });
    }
  },
  mounted() {
    if (this.$route.query.q) {
      this.searchEntry = this.$route.query.q;
      this.handleSearch();
    }
  },
  watch: {
    searchEntry() {
      if (this.searchEntry === '' || !this.searchEntry) {
        this.results = [];
      }
    }
  }
};
</script>

<style>
.search__inner {
  padding: 25px;
}
.search__head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 75px;
  gap: 235px;
}

.search__form {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.search__label {
  display: flex;
  align-items: center;
  width: 625px;
  position: relative;
}
.search__input {
  width: 100%;
  height: 36px;
  border-radius: 10px;
  border: none;
  padding: 0 30px 0 15px;
  color: var(--teamBlue);
  outline: none;
}
.search__icon {
  position: absolute;
  right: 20px;
}
.search__results {
  text-align: center;
  max-width: 610px;
  margin: 0 auto;
}
.search__results-content {
  padding: 15px;
}
.search__results-content .text {
  padding-bottom: 24px;
}
@media screen and (min-width: 320px) and (max-width: 990px) {
  .search__form-wrapper {
    max-width: 100%;
  }
  .search__head {
    flex-direction: column;
    gap: 50px;
  }
}
</style>
