import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { i18n } from 'vue-lang-router';
import './assets/css/main.css';
import '../node_modules/vue-select/dist/vue-select.css';
import { createMetaManager, plugin as vueMetaPlugin } from 'vue-meta';

const metaManager = createMetaManager();

const app = createApp(App);

app.use(router).use(i18n).use(metaManager).use(vueMetaPlugin).mount('#app');
