<template>
  <main>
    <TeamHero :title="$t('home.hero.title')" :desc="$t('home.hero.body')" />
    <TeamPayServices
      :title="$t('home.services.title')"
      :desc="$t('home.services.body')"
    />
    <HowItWorks
      :title="$t('home.howItWorks.title')"
      :desc="$t('home.howItWorks.body')"
    />
    <BranchesMap :title="$t('home.branches.title')" />
  </main>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import TeamHero from '@/components/Hero/TeamHero.vue';
import TeamPayServices from '@/components/TeamPayServices/TeamPayServices.vue';
import HowItWorks from '@/components/HowItWorks/HowItWorks.vue';
import LoadingSpinnerVue from '@/components/UI/LoadingSpinner.vue';
import Partners from '../components/Partners/Partners.vue';

const BranchesMap = defineAsyncComponent({
  loader: () => import('@/components/BranchesMap/BranchesMap.vue'),
  loadingComponent: LoadingSpinnerVue,
});
export default {
  name: 'Home',
  components: {
    TeamHero,
    TeamPayServices,
    HowItWorks,
    BranchesMap,
    Partners,
  },
  metaInfo() {
    return {
      title: this.$t('meta.pages.home.title'),
      description: this.$t('meta.pages.home.description'),
    };
  },
};
</script>
