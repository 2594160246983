<template>
  <div class="about__features">
    <div class="about__right">
      <img
        class="about__img"
        src="@/assets/img/images/about_payments.png"
        alt=""
      />
    </div>
    <div class="about__left">
      <h3 class="title fs32">{{ title }}</h3>
      <p class="desc fs16 pb30">{{ body }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "AboutPayments",
  props: {
    title: String,
    body: String
  },
};
</script>

<style scoped>
.about__features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
  max-width: 1150px;
  width: 1150px;
  margin: 0 auto;
  margin-bottom: 36px;
}

.about__features-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}


.about__features .about__img {
  max-width: 415px;
  max-height: 450px;
  margin-left: 35px;
}

@media screen and (min-width: 320px) and (max-width: 650px) {
  .about__features {
    flex-direction: column;
    max-width: 100%;
  }

  .about__features-list {
    width: 80%;
    margin: 0 auto;
  }

  .about__features .about__img {
    margin: 0 auto;
    max-width: 315px;
    max-height: 350px;
  }
}

@media screen and (min-width: 651px) and (max-width: 1080px) {
  .about__features {
    flex-direction: row;
    max-width: 100%;
  }

  .about__features .about__img {
    max-width: 315px;
    max-height: 350px;
    margin: 0 auto;
  }
}</style>
