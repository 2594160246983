<template>
  <div class="header__logo">
    <router-link
      aria-label="link to home page"
      class="logo"
      :to="{ path: '/' }"
    >
      <img
        :src="src"
        alt=""
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    src: String
  }
};
</script>

<style scoped>
.header__logo {
  max-width: 205px;
  width: 100%;
  display: inline-block;
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  .logo img {
    max-width: 50px;
    max-height: 40px;
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .header__logo {
    margin-right: 50px;
  }

  .logo {
    width: 100%;
  }
}</style>
