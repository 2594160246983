<template>
  <main style="padding-top: 90px">
    <section class="charge">
      <div class="container--xl">
        <div class="charge__inner">
          <div class="heading ma0">
            <h1 class="title fs64 pb30 m0a">{{ $t('howToCharge.head.title') }}</h1>
            <p class="desc fs16 m0a">
              {{ $t('howToCharge.head.body') }}
            </p>
          </div>
          <div class="charge__features">
            <div class="charge__features-info">
              <h2 class="title fs24 mb25">{{ $t('howToCharge.block0.title') }}</h2>
              <div class="charge__features-box">
                <p class="title mb10">{{ $t('howToCharge.block0.text1') }}</p>
                <ul>
                  <li>Type 1 /Tesla</li>
                  <li>Type 2 /GBT</li>
                </ul>
              </div>
              <div class="charge__features-box">
                <p class="title mb10">{{ $t('howToCharge.block0.text2') }}</p>
                <ul>
                  <li>CCS1 / Tesla</li>
                  <li>CCS2</li>
                  <li>ChadeMo</li>
                  <li>GBT</li>
                </ul>
              </div>
            </div>
            <div class="charge__features-img">
              <img
                src="../assets/img/images/charge-1.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="grid">
      <div class="container--xl">
        <div class="grid__inner">
          <div class="grid__item">
            <div class="grid__item-info">
              <p class="title fs24 mb45">{{ $t('howToCharge.block1.title') }}</p>
              <ul>
                <li>{{ $t('howToCharge.block1.item1') }}</li>
                <li>{{ $t('howToCharge.block1.item2') }}</li>
                <li>{{ $t('howToCharge.block1.item3') }}</li>
              </ul>
            </div>
            <div class="grid__item-image">
              <img
                src="../assets/img/images/charge-2.png"
                alt=""
              >
            </div>
          </div>
          <div class="grid__item">
            <div class="grid__item-info">
              <p class="title fs24 mb45">{{ $t('howToCharge.block3.title') }} </p>
              <p class="desc">{{ $t('howToCharge.block3.text') }}</p>
            </div>
            <div class="grid__item-image">
              <img
                src="../assets/img/images/charge-4.png"
                alt=""
              >
            </div>
          </div>
          <div class="grid__item">
            <div class="grid__item-info">
              <p class="title fs24 mb45">{{ $t('howToCharge.block2.title') }}</p>
              <p class="desc mb10">{{ $t('howToCharge.block2.text') }}
              </p>
            </div>
            <div class="grid__item-image">
              <img
                src="../assets/img/images/charge-5.png"
                alt=""
              >
            </div>
          </div>
          <div class="grid__item">
            <div class="grid__item-info">
              <p class="title fs24 mb45">{{ $t('howToCharge.block4.title') }} </p>
              <p class="desc mb10">{{ $t('howToCharge.block4.text') }}
              </p>
              <!-- <div class="statuses">
                <p class="title">Charger statuses are: </p>
                <span class="available">Available</span>
                <span class="busy">Busy</span>
                <span class="error"> Under Service</span>
              </div> -->
            </div>
            <div class="grid__item-image">
              <img
                src="../assets/img/images/charge-7.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="monitor">
      <div class="container--xl">
        <div class="monitor__inner">
          <div class="monitor__left">
            <h3 class="title fs24 mb20">{{ $t('howToCharge.block5.title') }}</h3>
            <p class="desc">{{ $t('howToCharge.block5.text') }}</p>
          </div>
          <div class="monitor__right">
            <img
              src="../assets/img/images/charge-6.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>

export default {
  name: "HowToCharge",

};
</script>

<style  scoped>
.charge__inner {
  display: flex;
  flex-direction: column;
}

.charge__features {
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: var(--teamLightGray);
  border-radius: 20px;
  padding: 59px 94px;
}

.charge__features-info {
  flex: 1;
}

.charge__features-img {
  flex: 1;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  margin-bottom: 32px;
}

li::before {
  content: "";
  background-color: var(--teamRed);
  font-weight: bold;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 12px;
}

.charge__features-img img {
  display: block;
  width: 100%;
}

.grid {
  margin-top: 24px;
}

.grid__inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 15px;
}

.grid__item {
  display: flex;
  border-radius: 20px;
  height: max-content;
  min-height: 525px;
  height: 100%;
}

.grid__item-info {
  padding-inline: 45px;
  padding-block: 39px;
  flex: 2;
}

.grid__item-image {
  flex: 3;
  overflow: hidden;
}

.grid__item-image img {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.grid__item:first-of-type {
  background-color: var(--teamSkyBlue);
}

.grid__item:nth-of-type(2) {
  background-color: #fff;
}

.grid__item:nth-of-type(3) {
  background-color: var(--teamBlue);
  color: #fff;
}

.grid__item:nth-of-type(4) {
  background-color: #E5DFDF;
}

.statuses {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.statuses span {
  font-weight: bold;
}

.statuses .available {
  color: rgba(55, 213, 80, 1);
}

.statuses .busy {
  color: rgba(229, 175, 35, 1);
}

.statuses .error {
  color: rgba(240, 78, 77, 1);
}

.monitor {
  margin-top: 24px;
}

.monitor__inner {
  display: flex;
  gap: 200px;
  background-color: #fff;
  border-radius: 20px;
}

.monitor__left {
  padding: 81px 141px;
  flex: 1;
}

.monitor__right {
  display: block;
  flex: 2;
}

.monitor__right img {
  display: block;
  width: 100%;
}


@media screen and (min-width: 320px) and (max-width: 550px) {
  .charge__features {
    flex-direction: column;
    padding: 29px;
    gap: 0;
  }

  .grid__inner {
    display: block;
  }

  .grid__item {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .grid__item-info {
    padding: 24px;
  }

  .monitor__inner {
    flex-direction: column;
    gap: 20px;
  }

  .monitor__left {
    padding: 24px;
  }

  .monitor__right {
    padding-inline: 24px;
  }
}
</style>