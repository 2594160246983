<template>
  <div class="partners">
    <div class="container">
      <div class="partners__inner">
        <carousel :transition="500" :breakpoints="breakpoints">
          <slide v-for="slide in partners" :key="slide">
            <img :src="slide" alt="">
          </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data () {
    return {
      partners: [
        require('../../assets/img/partners/alemad.png'),
        require('../../assets/img/partners/tesla.png'),
        require('../../assets/img/partners/volkswagen.png'),
        require('../../assets/img/partners/alemad.png'),
        require('../../assets/img/partners/tesla.png'),
        require('../../assets/img/partners/volkswagen.png'),
        require('../../assets/img/partners/alemad.png'),
        require('../../assets/img/partners/tesla.png'),
        require('../../assets/img/partners/volkswagen.png'),
      ],
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.95,
          snapAlign: 'start',
        },
      },
    };
  }
};
</script>

<style>
.partners {
  padding-block: 60px;
}

.partners .carousel__viewport {
  width: 80%;
  margin-inline: auto;
}

.partners .carousel__pagination-button {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #C4C4C4;
}

.partners .carousel__pagination-button--active {
  background-color: #F04E4D;
}

.partners .carousel__prev,
.carousel__next {
  background-color: transparent;
  color: #666E75;
}

.partners .carousel__icon {
  width: 35px;
}

.partners .carousel__pagination {
  margin-top: 25px;
}

@media screen and (min-width: 320px) and (max-width: 550px) {

  .partners {
    padding-block: 20px;
  }


  .partners .carousel__viewport {
    width: 100%;
  }

}
</style>