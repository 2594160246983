<template>
  <div class="owners">
    <div class="container">
      <div class="owners__inner">
        <p class="desc fs16">
          Automated processing of personal data - processing of personal data using computer technology. Blocking of
          personal data - a temporary suspension of the processing of personal data (except for cases when the processing
          is necessary to clarify personal data). Personal data information system - a set of personal data contained in
          databases and information technologies and technical means that ensure their processing. Depersonalization of
          personal data - actions as a result of which it is impossible to determine, without the use of additional
          information, the belonging of personal data to a specific subject of personal data. Processing of personal data
          - any action (operation) or a set of actions (operations) performed using automation tools or without using such
          tools with personal data, including collection, recording, systematization, accumulation, storage, clarification
          (update, change), extraction, use, transfer (distribution, provision, access), depersonalization, blocking,
          deletion, destruction of personal data. Operator - a state body, a municipal body, a legal entity or an
          individual, independently or jointly with other persons organizing and (or) processing personal data, as well as
          determining the purposes of processing personal data, the composition of personal data to be processed, actions
          (operations) performed with personal data. Personal data - any information relating directly or indirectly to a
          specific or identifiable individual (subject of personal data). Provision of personal data - actions aimed at
          disclosing personal data to a certain person or a certain circle of persons. Dissemination of personal data -
          actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or at
          acquaintance with the personal data of an unlimited number of persons, including the disclosure of personal data
          in the media, posting on information and telecommunication networks or providing access to personal data in any
          other way. Cross-border transfer of personal data - the transfer of personal data to the territory of a foreign
          state to the authority of a foreign state, to a foreign individual or foreign legal entity. Destruction of
          personal data - actions as a result of which it is impossible to restore the content of personal data in the
          personal data information system and (or) as a result of which material carriers of personal data are destroyed.
          1. General Provisions This document defines the policy of EkoCars LLC (hereinafter referred to as the Operator)
          in relation to the processing of personal data and discloses information on the measures taken to ensure the
          security of personal data at the Operator in order to protect the rights and freedoms of a person and citizen
          when processing his personal data, including protection rights to privacy, personal and family secrets. This
          document "Policy of" Eco Cars "LLC regarding the processing of personal data" (hereinafter - the Policy) has
          been developed in accordance with the Constitution of the Republic of Armenia, which determines the cases and
          features of processing personal data and ensuring the security and confidentiality of such information. The
          provisions of this Policy are binding on all employees of the Operator who process personal data, including
          those working in branches and separate divisions of the Operator. The provisions of this Policy are the basis
          for organizing work on the processing of personal data at the Operator, including for the development of
          internal regulatory documents governing the processing and protection of personal data at the Operator. In the
          event that certain provisions of this Policy come into conflict with the current legislation on personal data,
          the provisions of the current legislation apply. Subjects of personal data can send their request signed with an
          enhanced qualified electronic signature to the e-mail address info@ecocars.am The term for considering
          applications does not exceed 30 (thirty) days from the date of application. This Policy is a document to which
          unlimited access is provided. To ensure unrestricted access, the Policy, in particular, is published on the
          official website of the Operator at: https://ecocars.am
          2. Principles and conditions for the processing of personal data
          2.1. Principles of processing personal data The processing of personal data by the Operator is carried out on
          the basis of the following principles: - legality and fair basis; - restrictions on the processing of personal
          data to achieve specific, predetermined and legal purposes; - preventing the processing of personal data that is
          incompatible with the purposes of collecting personal data; - preventing the unification of databases containing
          personal data, the processing of which is carried out for purposes incompatible with each other; - processing
          only those personal data that meet the purposes of their processing; - compliance of the content and volume of
          processed personal data with the stated processing objectives; - preventing the processing of personal data that
          is redundant in relation to the stated purposes of their processing; - ensuring the accuracy, sufficiency and
          relevance of personal data in relation to the purposes of processing personal data; - destruction or
          depersonalization of personal data upon achievement of the goals of their processing or in case of loss of the
          need to achieve these goals, if the Operator cannot eliminate the violations of personal data, unless otherwise
          provided by federal law.

        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  props: {
    title: String,
    desc: String
  }
}
</script>

<style></style>
